import { navigate } from "gatsby"

import { setAuthToken } from "../../../api/http"
import { ForbiddenError, BadRequestError } from "../../../api/error"
import {
  setAccount,
  setToken,
  loadingAccount,
  clearToken,
  clearAccount,
} from "."

// TODO: seperate authentication action from error handling and dispatching.
export function SSOLogin() {
  return async (dispatch, _getState, { zeusAPI, toastActions }) => {
    try {
      const body = await zeusAPI.externalAuths.SSOLogin()
      setAuthToken(body.token)
      dispatch(setAccount(body.user))
      dispatch(setToken(body.token))
      // setUserId(body.user.email)
      toastActions.showSuccessToast("Logged in!")
    } catch (error) {
      if (error instanceof ForbiddenError) {
        throw new Error("Invalid email/password.")
      } else if (error instanceof BadRequestError) {
        throw new Error(
          'For security reasons, please reset your password using the "Forgot password" link.'
        )
      } else {
        throw error
      }
    }
  }
}

export const login = (email, password) => {
  return async (dispatch, _getState, { zeusAPI, toastActions }) => {
    try {
      const { data } = await zeusAPI.auth.login(email, password)
      setAuthToken(data.token)
      dispatch(setAccount(data.user))
      dispatch(setToken(data.token))

      toastActions.showSuccessToast("Logged in!")
    } catch (error) {
      let statusCode
      try {
        statusCode = error.response.status
      } catch {
        statusCode = 400
      }

      if (statusCode >= 400 && statusCode < 500) {
        toastActions.showSuccessToast(
          "Unable to log in with provided credentials."
        )
      } else {
        toastActions.showSuccessToast("Error")
      }

      if (error instanceof ForbiddenError) {
        throw new Error("Invalid email/password.")
      } else if (error instanceof BadRequestError) {
        throw new Error(
          'For security reasons, please reset your password using the "Forgot password" link.'
        )
      } else {
        throw error
      }
    }
  }
}

export function updateProfile(profile, notoast) {
  return async (dispatch, _getState, { zeusAPI, toastActions }) => {
    dispatch(loadingAccount())
    const result = await zeusAPI.account.patchAccount(profile)
    dispatch(setAccount(result.data))
    // trackEditedProfile()
    if (!notoast) toastActions.showSuccessToast("Profile saved!")
    return result
  }
}

export function logout() {
  return (dispatch, _getState, { toastActions }) => {
    dispatch(clearToken())
    dispatch(clearAccount())
    setAuthToken(null)
    navigate("/")
    toastActions.showSuccessToast("See you soon!")
  }
}

export const resendActivationEmail = () => {
  return async (_dispatch, _getState, { zeusAPI, toastActions }) => {
    await zeusAPI.account.postResendActivation()
    toastActions.showSuccessToast(
      "We have resent the activation email, please check your inbox"
    )
  }
}

export const googleLogin = (token) => {
  return async (dispatch, _getState, { zeusAPI, toastActions }) => {
    try {
      const { body } = await zeusAPI.externalAuths.googleLogin(token)
      setAuthToken(body.token)

      const user = await zeusAPI.account.getAccount()
      dispatch(setAccount(user.body))
      dispatch(setToken(body.token))

      toastActions.showSuccessToast("Logged in!")
    } catch (error) {
      if (error instanceof ForbiddenError) {
        throw new Error("Invalid email/password.")
      } else if (error instanceof BadRequestError) {
        throw new Error(
          'For security reasons, please reset your password using the "Forgot password" link.'
        )
      } else {
        throw error
      }
    }
  }
}

export const activate = () => {
  return async (dispatch, _getState, { zeusAPI }) => {
    const user = await zeusAPI.account.getAccount()
    dispatch(setAccount(user.data))
  }
}

export const loadAccount = activate

export const signup = (name, email, password, notoast) => {
  return async (dispatch, _getState, { zeusAPI, toastActions }) => {
    const { data } = await zeusAPI.auth.signup(name, email, password)
    setAuthToken(data.token)
    await dispatch(setAccount({ ...data, token: undefined }))
    await dispatch(setToken(data.token))
    if (!notoast) toastActions.showSuccessToast("Signed up!")
  }
}

export const forgotPassword = (email) => {
  return async (_dispatch, _getState, { zeusAPI }) => {
    await zeusAPI.auth.forgotPassword(email)
  }
}

export const resetPassword = (token, code, password) => {
  return async (_dispatch, _getState, { zeusAPI }) => {
    await zeusAPI.account.resetPassword(token, code, password)
  }
}

export default {
  SSOLogin,
  updateProfile,
  signup,
  logout,
  login,
}
