import styled from "styled-components"
export const ForgotPasswordCSS = styled.div`
  button {
    width: 100%;
  }

  input {
    margin-bottom: 0;
  }

  .info {
    margin-bottom: 2em;
    line-height: 140%;
  }

  .login-head {
    margin-bottom: 1em !important;
  }

  #reset-password-desc {
    font-family: "itc";
    font-weight: normal;
    font-size: 0.85em;
    text-align: center;
    margin-bottom: 2em;
    line-height: 140%;
  }

  .logo {
    display: none;
  }
`
