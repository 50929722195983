import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import PropTypes from "prop-types"
import NavbarCSS from "./NavbarCSS"
import { Button } from "jasper-ui"
import { LocalizedLink } from "gatsby-theme-i18n"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { isLoggedIn, getAuthUser } from "../../redux/slices/session/selectors"
import { logout } from "../../redux/slices/session/thunks"

import findByType from "./utils/findByType"

// images
import ToggleClose from "../../../assets/images/navbar-assets/toggle_close.svg"
import ToggleOpen from "../../../assets/images/navbar-assets/toggle_open.svg"
import User from "../../../assets/images/navbar-assets/user.svg"
import Arrow from "../../../assets/images/navbar-assets/arrow.svg"

const Brand = () => null
const Collapse = () => null
const Toggle = () => null
const BREAKPOINT = 1060

const Navbar = ({ children, setShowLogin }) => {
  const dispatch = useDispatch()
  const loggedIn = useSelector(isLoggedIn)
  const userData = useSelector(getAuthUser)

  const [show, setShow] = useState(false)
  const [showDropDown, setShowDropDown] = useState(false)
  const [showDropDownMobile, setShowDropDownMobile] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const isMobile = windowWidth <= BREAKPOINT
  const [isDashboard, setIsDashboard] = useState(false)
  const [isApp, setIsApp] = useState(false)
  const [isProfile, setIsProfile] = useState(false)

  const getPath = () => {
    const path = window !== undefined && window.location.pathname
    return path || ""
  }

  const handlePath = () => {
    setIsProfile(
      getPath() === "/dashboard/profile/" || getPath() === "/dashboard/profile"
    )
    setIsDashboard(getPath() === "/dashboard/" || getPath() === "/dashboard")
    setIsApp(getPath() === "/applications/" || getPath() === "/applications")
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    handlePath()

    const onResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  const renderBrand = () => {
    const brand = findByType(children, Brand)[0]
    if (!brand) return

    let brandText, brandTextMobile
    const brandChild = brand.props.children || ""

    if (!Array.isArray(brandChild)) {
      brandText = brandTextMobile = brandChild
    } else {
      brandTextMobile = brandChild.filter((b) => b.props.mobile)
      brandText = brandChild.filter((b) => !b.props.mobile)
    }

    const brandLink = brand.props?.href || "/"

    return (
      <div className="brand">
        <div className="brandWrapper">
          <Link to={brandLink}>
            <div className={`brandText ${show ? "show" : ""}`}>
              <div className="desktop">{brandText}</div>
              <div className="mobile">{brandTextMobile}</div>
            </div>
          </Link>
          {renderToggle()}
        </div>
      </div>
    )
  }

  const renderToggle = () => {
    const toggle = findByType(children, Toggle)[0]

    if (!toggle) return

    return (
      <div className="toggle" onClick={() => setShow((prev) => !prev)}>
        {show ? <img src={ToggleClose} /> : <img src={ToggleOpen} />}
      </div>
    )
  }

  const renderCollapse = () => {
    const collapse = findByType(children, Collapse)[0]
    if (!collapse) return

    return (
      <div className={`collapse ${show || !isMobile ? "show" : ""}`}>
        {collapse.props.children}
        <div
          className="button-container"
          onMouseEnter={() => setShowDropDown(true)}
          onMouseLeave={() => setShowDropDown(false)}
        >
          {!loggedIn ? (
            <Button
              onClick={() => setShowLogin()}
              className="navbar-btn secondary"
            >
              Sign In
            </Button>
          ) : (
            <div className="user-container">
              <img
                id="user"
                className={`${showDropDown ? "hover" : ""}`}
                src={User}
                alt=""
              />
              {isMobile && (
                <div className="mobile-dropdown">
                  <div onClick={() => setShowDropDownMobile((prev) => !prev)}>
                    <h2>{userData.name}</h2>
                    <img src={Arrow} alt="" />
                  </div>
                  <DropDownMobile />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  const DropDown = () => (
    <div className="dropdown-container">
      <div
        onMouseEnter={() => setShowDropDown(true)}
        onMouseLeave={() => setShowDropDown(false)}
        className={`dropdown ${showDropDown ? "show" : ""}`}
      >
        <div className="top">
          <Link
            to="/dashboard/profile"
            className={`${isProfile ? "active" : ""}`}
          >
            Profile
          </Link>
          <Link to="/dashboard" className={`${isDashboard ? "active" : ""}`}>
            Dashboard
          </Link>
          <LocalizedLink
            to="/applications"
            className={`${isApp ? "active" : ""}`}
          >
            Job Applications
          </LocalizedLink>
        </div>
        <div className="bottom">
          <h2 onClick={() => dispatch(logout())}>Sign out</h2>
        </div>
      </div>
    </div>
  )

  const DropDownMobile = () => (
    <div className="dropdown-container">
      <div className={`dropdown ${showDropDownMobile ? "show" : ""}`}>
        <div className="top">
          <Link
            to="/dashboard/profile"
            className={`${isProfile ? "active" : ""}`}
          >
            Profile
          </Link>
          <Link to="/dashboard" className={`${isDashboard ? "active" : ""}`}>
            Dashboard
          </Link>
          <Link to="/applications" className={`${isApp ? "active" : ""}`}>
            Job Applications
          </Link>
        </div>
        <div className="bottom">
          <h2 onClick={() => dispatch(logout())}>Sign out</h2>
        </div>
      </div>
    </div>
  )

  return (
    <NavbarCSS>
      <div
        className={`navbar ${
          isMobile ? "mobile" : ""
        } ${isMobile} ${windowWidth} ${windowWidth < BREAKPOINT}`}
      >
        <div className="navbarWrapper">
          {renderBrand()}
          {renderCollapse()}
        </div>
        {!isMobile && loggedIn && <DropDown />}
      </div>
    </NavbarCSS>
  )
}

Navbar.propTypes = {
  children: PropTypes.node,
}

Navbar.Brand = Brand
Navbar.Collapse = Collapse
Navbar.Toggle = Toggle

export default Navbar
