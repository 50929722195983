import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { LocalizedLink } from "gatsby-theme-i18n"

import PropTypes from "prop-types"
import NavCSS from "./NavCSS"
import findByType from "../utils/findByType"

// redux
import { isLoggedIn } from "../../../redux/slices/session/selectors"
import { useSelector } from "react-redux"

const Empty = () => null

const Nav = ({ children }) => {
  // const [curPath, setCurPath] = useState("")

  // useEffect(() => {
  //   setCurPath(window.location.pathname)
  // }, [])

  const loggedIn = useSelector(isLoggedIn)

  // const getPath = () => {
  //   return curPath
  // }

  // const checkPath = (link) => {
  //   if (link.props.href === "/") return getPath() === "/"
  //   else {
  //     return (
  //       getPath().startsWith(link.props.href) ||
  //       getPath().startsWith("/id" + link.props.href)
  //     )
  //   }
  // }

  const renderLink = () => {
    const links = findByType(children, Empty)
    if (!links) return

    // const customAccent = (idx) => {
    //   switch (idx) {
    //     case 2:
    //       return 'jobfair'
    //     case 4:
    //       return 'acad'
    //     default:
    //       return ''
    //   }
    // }

    return links.map((link, idx) =>
      link.props.hideWhenLoggedIn ? (
        loggedIn && <Nav.LinkItem key={idx} idx={idx} link={link} />
      ) : (
        <Nav.LinkItem key={idx} idx={idx} link={link} />
      )
    )
  }

  return (
    <NavCSS>
      <ul className="nav">{renderLink()}</ul>
    </NavCSS>
  )
}

Nav.propTypes = {
  children: PropTypes.node,
}

Nav.Link = Empty

Nav.LinkItem = ({ link, idx }) => {
  const [isPath, setIsPath] = useState(false)

  useEffect(() => {
    setIsPath(checkPath(link))
  }, [])

  const getPath = () => {
    const path = window !== undefined && window.location.pathname
    return path || ""
  }

  const checkPath = (link) => {
    if (link.props.href === "/") return getPath() === "/"
    if (link.props.href === "/job-fair") {
      return getPath().startsWith("/job") || getPath().startsWith("/id/job")
    } else {
      return (
        getPath().startsWith(link.props.href) ||
        getPath().startsWith("/id" + link.props.href)
      )
    }
  }

  const customAccent = (idx) => {
    switch (idx) {
      case 1:
        return "compfest-talks"
      case 2:
        return "jobfair"
      case 4:
        return "acad"
      default:
        return ""
    }
  }

  return (
    <li className={`navItem ${customAccent(idx)} ${isPath ? "active" : ""}`}>
      {link.props.localized ? (
        <LocalizedLink to={link.props.href}>
          {link.props.children}
        </LocalizedLink>
      ) : (
        <Link to={link.props.href}>{link.props.children}</Link>
      )}
    </li>
  )
}

export default Nav
