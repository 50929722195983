import React from "react"
import { connect } from "react-redux"
import { isLoggedIn } from "../redux/slices/session/selectors"
import { navigate } from "gatsby"

export function withLoggedIn(Component) {
  function WithLoggedIn(props) {
    return props.isLoggedIn ? (
      <Component {...props} />
    ) : (
      <Component>
        {typeof window !== `undefined` ? navigate("/") : ""}
      </Component>
    )
  }

  const wrappedComponentName =
    Component.displayName || Component.name || "Component"

  WithLoggedIn.displayName = `withLoggedIn(${wrappedComponentName})`

  return WithLoggedIn
}

export default function (component) {
  const mapStateToProps = (state) => ({
    isLoggedIn: isLoggedIn(state),
  })
  return connect(mapStateToProps, null)(withLoggedIn(component))
}
