import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Button from "../../Button"
import closeSvg from "../../../../assets/images/common/close.svg"
// import { useSelector } from "react-redux"
// import { getAuthUser } from "../../../redux/slices/session/selectors"
// import { toastActions } from "../../../redux/toast"

const Styles = styled.div`
  @keyframes getInside {
    from {
      right: 100%;
    }
    to {
      right: 0;
    }
  }

  @keyframes getOut {
    from {
      left: 0;
    }
    to {
      left: 100%;
    }
  }

  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  width: 100%;
  background: #212121;
  overflow: hidden;

  .announcement-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    padding: 30px 22px;
    margin: auto;

    .close-btn {
      cursor: pointer;
    }

    & > * {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .announce-display {
    position: relative;
    right: 100%;
    animation: getInside 5s forwards;
  }

  .announce-invis {
    position: absolute;
    width: 95%;
    left: 0;
    top: 0;
    animation: getOut 5s forwards;
  }

  @media (min-width: 1200px) {
    .announce-invis {
      margin-left: calc(50% - 600px);
      margin-right: calc(50% - 600px);
    }
  }

  h2 {
    margin-top: 0.75rem;
    margin-bottom: 0;
  }

  .longer-area {
    display: flex;
    align-items: center;

    .jasper-button {
      width: 150px;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  @media (max-width: 650px) {
    .announcement-wrapper {
      flex-wrap: wrap;
      padding: 22px 12px;

      .close-btn {
        position: absolute;
        right: 18px;
        top: 18px;
      }

      .longer-area {
        padding-top: 1rem;
        width: 100%;
        justify-content: center;
      }
    }
  }
`

let realIsOne = true

export default function StickyAnnouncement() {
  const data = useStaticQuery(graphql`
    query CompfestTalksRundownQuery {
      allContentfulCompfestTalksDayList {
        nodes {
          day
          compfest_talks_rundown {
            name
            time
            channel
          }
        }
      }
    }
  `)
  // const userData = useSelector(getAuthUser)
  const dayLists = data.allContentfulCompfestTalksDayList.nodes
  const dayToDate = {
    "Day 1": new Date(2020, 9, 3, 0, 0, 0),
    "Day 2": new Date(2020, 9, 4, 0, 0, 0),
  }

  const [closed, setClosed] = useState(false)
  const [isOne, setOne] = useState(true)
  const [eventList, setEventList] = useState([])

  let oneInterval = null
  useEffect(() => {
    const currentDay = new Date()
    const newEventList = []
    dayLists.forEach((day) => {
      const date = dayToDate[day.day]
      if (
        !date ||
        currentDay.getMonth() !== date.getMonth() ||
        currentDay.getDate() !== date.getDate()
      ) {
        return
      }
      day.compfest_talks_rundown.forEach((event) => {
        const timeLst = event.time.split(/[ :-]+/)
        const startEvent = new Date()
        startEvent.setHours(timeLst[0])
        startEvent.setMinutes(timeLst[1])
        startEvent.setSeconds(0)
        const endEvent = new Date()
        endEvent.setHours(timeLst[2])
        endEvent.setMinutes(timeLst[3])
        endEvent.setSeconds(0)

        if (startEvent <= currentDay && currentDay <= endEvent) {
          newEventList.push(event)
        }
      })
    })
    setEventList(newEventList)

    oneInterval = setInterval(() => {
      realIsOne = !realIsOne
      setOne(realIsOne)
    }, 15000)
    return () => {
      clearInterval(oneInterval)
    }
  }, [])

  if (eventList.length < 1 || eventList.length > 2) {
    return <></>
  }

  if (closed) {
    return <></>
  } else {
    return (
      <Styles>
        {eventList.map(({ name }, index) => (
          <div
            key={index}
            className={`announcement-wrapper ${
              eventList.length === 1
                ? ""
                : isOne ^ (index % 2 === 1)
                ? "announce-display"
                : "announce-invis"
            }`}
          >
            <div>
              <div>LIVE NOW</div>
              <h2>{name}</h2>
            </div>
            <div className="longer-area">
              <Button to="/compfesttalks/player">WATCH NOW</Button>
              <div className="close-btn" onClick={() => setClosed(true)}>
                <img src={closeSvg} alt="Close" />
              </div>
            </div>
          </div>
        ))}
      </Styles>
    )
  }
}
