import React, { useState } from "react"
// import { useLocation } from 'react-router-dom'
import { Formik, Form, Field } from "formik"
import { connect } from "react-redux"
import { resetPassword } from "../../redux/slices/session/thunks"
import { navigate } from "gatsby"
import { isLoggedIn } from "../../redux/slices/session/selectors"
import LoginPageWrapper from '../LoginPageComponent/LoginPageWrapper'
import ResetPasswordCSS from './ResetPasswordCSS'
import Button from '../Button'
import { toastActions } from '../../redux/toast'

function ResetPasswordComponent(props) {
  const [isSent, setSent] = useState(false)
  const path = typeof window !== "undefined" && window.location.search
  const token = path.split("?token=")[1]

  return (
    <ResetPasswordCSS>
      <LoginPageWrapper setShowLogin={() => props.closeResetPassword()} title="RESET PASSWORD">
        {!isSent && (
          <>
            <Formik
              initialValues={{
                code: '',
                password: ''
              }}
              onSubmit={async ({ code, password }) => {
                try {
                  await props.resetPassword(token, code, password)
                  navigate("/dashboard")
                } catch {
                  toastActions.showErrorToast("Invalid OTP Code!")
                }
              }}
            >
              <Form>
                <label htmlFor="code">OTP Code</label>
                <div className="otp-input">
                  <h3>OTP</h3>
                  <Field
                    name="code"
                    type="text"
                    placeholder="XXXXXXX"
                  />
                </div>

                <label htmlFor="password">New Password</label>
                <Field
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                />

                <Button
                  type="submit"
                  className="submit-btn"
                >
                  Submit
                </Button>
              </Form>
            </Formik>
            <h1 className="register">
              Remember your password? <a onClick={() => props.openLogin()} >Sign In</a>
            </h1>
          </>
        )}

        {isSent && (
          <>
            <p>
            Your password has succesfully reseted, now you can Sign In with your new password.
            </p>
            <Button
              className="submit-btn"
              onClick={() => props.openLogin()}>
              Sign In
            </Button>
          </>
        )}
      </LoginPageWrapper>
    </ResetPasswordCSS>
  )
}

export default connect(
  state => ({
    isLoggedIn: isLoggedIn(state),
  }),
  { resetPassword }
)(ResetPasswordComponent)
