import React, { useState } from "react"
import axios from "axios"

import { toastActions } from "../../../redux/toast"
import { SubscribeFormContainer } from "./style"

const SubscribeForm = () => {
  const [email, setEmail] = useState("")
  const [isLoading, setLoading] = useState(false)

  const submitEmail = (event) => {
    event.preventDefault()
    if (isLoading) return
    setLoading(true)
    axios
      .post("https://api.compfest.id/registrations/events/89/teams/anon/", {
        email: email,
        additional: "[]",
      })
      .then((resp) => {
        if (resp.data.message === "Create new registrar") {
          toastActions.showToast("Email successfully registered")
        } else if (resp.data.message === "Duplicate anon registrar") {
          toastActions.showToast("Email already registered")
        } else {
          throw new Error("Failed")
        }
      })
      .catch(() => {
        toastActions.showToast("Failed registerring email")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <SubscribeFormContainer onSubmit={submitEmail}>
      <input
        type="email"
        className="email-input"
        placeholder="Input your e-mail here"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      {isLoading ? (
        <button className="subscribe-button disabled">Loading..</button>
      ) : (
        <button type="submit" className="subscribe-button">
          Subscribe
        </button>
      )}
    </SubscribeFormContainer>
  )
}

export default SubscribeForm
