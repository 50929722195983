import styled from "styled-components"
const ResetPasswordCSS = styled.div`
  button {
    width: 100%;
  }

  p {
    font-family: "itc";
  }

  .login-head {
    margin-bottom: 1em;
  }

  .logo {
    display: none;
  }

  .submit-btn {
    margin-top: 2em;
  }

  .otp-input {
    font-size: 1em;
    border: 2px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 5px;
    background: none;
    color: #f3f3f3;
    padding: 0.2em 0.5em;
    margin: 0.5em 0;
    margin-bottom: 1.5em;
    display: flex;
    align-items: center;
  }

  .otp-input input {
    width: 100%;
    position: relative;
    border: none;
    outline: none;
    margin: 0;
    height: 2em;
    border-left: 1px solid #f3f3f3;
    border-radius: 0;
  }

  .otp-input h3 {
    font-size: 1em;
    font-weight: normal;
    margin: 0;
    padding-right: 0.4em;
  }
`
export default ResetPasswordCSS
