import * as Yup from "yup"

export const OprecProfileSchema = Yup.object().shape({
  name: Yup.string(),
  firstName: Yup.string().required("Please enter your first name."),
  lastName: Yup.string().required("Please enter your last name."),
  institution: Yup.string().required("Please enter your institution."),
  email: Yup.string().email().required("Please enter your email."),
  gender: Yup.string()
    .oneOf(["M", "F", "U"], "Please select a gender.")
    .required("Please select a gender.")
    .nullable(),
  phone_number: Yup.string()
    .matches(/^\+?\d{8,15}$/, "Please enter a valid phone number.")
    .required("Please enter your phone number.")
    .nullable(),
  birthdate: Yup.string()
    .matches(
      /\d{4}-\d{2}-\d{2}/,
      "Please enter your birthdate in the following format YYYY-MM-DD"
    )
    .required("Please enter your birth date.")
    .nullable(),
  oldPassword: Yup.string().min(8),
  password: Yup.string().min(8),
  rePassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password do not match"
  ),
})
