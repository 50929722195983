import React from "react"
import { Link } from "gatsby"
import { FooterContainer } from "./style"
import Instagram from "../../../assets/images/common/logo_instagram.svg"
import Facebook from "../../../assets/images/common/logo_facebook.svg"
import twitter from "../../../assets/images/common/logo_twitter.svg"
import Line from "../../../assets/images/common/logo_line.svg"
import LinkedIn from "../../../assets/images/common/logo_linkedIn.svg"
import youtube from "../../../assets/images/common/logo_youtube.svg"
import compfest from "../../../assets/images/compfest-white.svg"

import SubscribeForm from "./SubscribeForm"

class Footer extends React.Component {
  render() {
    return (
      <FooterContainer>
        <div className="row">
          <div className="column" id="ft-left">
            <div id="logo">
              <Link to="/">
                <img src={compfest} alt="COMPFEST" />
              </Link>
            </div>
            <div>
              <h4 className="slogan">Empower Breakthrough</h4>
              <span className="deskripsi-singkat">
                An annual one-stop IT event run by students of Faculty of
                Computer Science, University of Indonesia
              </span>
            </div>
          </div>
          <div className="column" id="ft-right">
            <span className="ft-section-title">Find Us on Social Media!</span>
            <div id="socmed">
              <a id="fb" href="https://www.facebook.com/COMPFEST">
                <img src={Facebook} alt="FACEBOOK-COMPFEST" />
              </a>
              <a id="tw" href="https://twitter.com/COMPFEST">
                <img src={twitter} alt="TWITTER-COMPFEST" />
              </a>
              <a
                id="yb"
                href="https://www.youtube.com/channel/UC03QmhwvQEWVI608A-RCEpw"
              >
                <img src={youtube} alt="YOUTUBE-COMPFEST" />
              </a>
              <a id="ig" href="https://www.instagram.com/compfest">
                <img src={Instagram} alt="INSTAGRAM-COMPFEST" />
              </a>
              <a id="ln" href="https://line.me/R/ti/p/%40compfest">
                <img src={Line} alt="LINE-COMPFEST" />
              </a>
              <a id="in" href="https://www.linkedin.com/company/compfest">
                <img src={LinkedIn} alt="LINKEDIN-COMPFEST" />
              </a>
            </div>
            <span className="ft-section-title">Subscribe to COMPFEST</span>
            <SubscribeForm />
          </div>
        </div>
        <div className="separator" />
        <div id="footer-bottom">
          <span id="copyright">© 2020 COMPFEST</span>
          <div id="footer-link">
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/terms-of-service">Terms of Service</a>
          </div>
        </div>
      </FooterContainer>
    )
  }
}

export default Footer
