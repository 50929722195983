import { createSelector } from "@reduxjs/toolkit"

export const getAuthUser = (state) => state.session.account

export const getUserToken = (state) => state.session.token

export const isLoggedIn = createSelector(getUserToken, (token) => !!token)

export const shortnameSelector = createSelector(getAuthUser, (account) => {
  if (!account) {
    return "Anonymous"
  }

  const { name } = account
  if (!name) {
    return "No Name"
  }

  const [first, last] = name.split(" ")
  if (!last || !last[0]) {
    return first
  }
  return `${first} ${last[0]}.`
})
