import styled from "styled-components"
const NavCSS = styled.div`
  .nav {
    --accent: linear-gradient(90deg, #e8674f 0%, #ffb23e 100%);
    --accent-2: linear-gradient(
      113.24deg,
      #0083fb 0%,
      #00e3f0 99.67%,
      #00ddf1 99.67%
    );
    --accent-3: linear-gradient(135deg, #279686 0%, #fdd262 100%);
    --accent-4: linear-gradient(
      90deg,
      #02dbfc 0%,
      #00fdd2 34.98%,
      #006ce1 64.97%,
      #ce0040 100%
    );
    display: flex;
    justify-content: space-between;
    width: 40em;
    height: 100%;
  }

  .navItem {
    font-size: 1em;
    display: flex;
    line-height: 0px;
    padding: 1em 0;
    font-weight: normal;
    color: white;
  }

  .navItem:hover {
    opacity: 80%;
  }

  /*.navItem:not(:last-child) {
    margin-right: 3em;
  }*/

  .navItem.active {
    background: linear-gradient(90deg, #e8674f 0%, #ffb23e 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  .navItem > * {
    flex: 1 1;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    width: max-content;
  }

  @media (max-width: 1060px) {
    .nav {
      flex-direction: column;
      height: max-content;
      width: 100%;
    }

    .navItem {
      margin-bottom: 1.5rem;
      font-size: 14px;
    }
  }

  @media only screen and (min-width: 1060px) {
    font-size: 1em;

    .navItem {
      position: relative;
      opacity: 50%;
      padding: 2em 0;
    }

    .navItem.active::before {
      content: " ";
      position: absolute;
      background: var(--accent);
      height: 4px;
      width: calc(100% + 14px);
      transform: translateX(-50%);
      left: 50%;
      bottom: 0;
    }

    li.navItem.acad.active::before {
      background: var(--accent-2) !important;
    }

    li.navItem.jobfair.active::before {
      background: var(--accent-3) !important;
    }
    li.navItem.compfest-talks.active::before {
      background: var(--accent-4) !important;
    }

    .navItem.active {
      opacity: 100%;
      background: white;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  @media (min-width: 1060px) and (max-width: 1140px) {
    .nav {
      width: 35em;
    }
  }

  /*@media (min-width: 1920px) {
    font-size: 1.5em;
  }*/
`
export default NavCSS
