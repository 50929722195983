import styled from "styled-components"
const NavbarCSS = styled.div`
  position: fixed;
  z-index: 20;
  width: 100vw;
  top: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

  .desktop {
    display: block;
  }

  .mobile {
    display: none !important;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: DM Sans !important;
  }

  .dropdown-container {
    display: flex;
    justify-content: flex-end;
    padding: 0 7.125rem;

    .dropdown.show {
      max-height: 1000px;
    }

    .dropdown {
      background: black;
      height: max-content;
      max-height: 0;
      overflow-y: hidden;
      box-sizing: border-box;

      h2 {
        font-size: 1rem;
        cursor: pointer;

        &:hover {
          opacity: 0.75;
        }
      }

      .top {
        padding: 1rem 1rem 0 1rem;

        a {
          display: block;
          font-weight: normal;
          margin-bottom: 1rem;
          color: #f3f3f3;

          &.active {
            color: #ffcb15;
          }
        }
      }

      .bottom {
        border-top: 0.5px solid #f3f3f3;
        padding: 1rem;
        h2 {
          color: #cb0000;
          font-weight: bold;
        }
      }
    }
  }

  #user {
    cursor: pointer;
    height: 2.1875rem;
    opacity: 0.6;

    &.hover {
      opacity: 1;
    }
  }

  button {
    white-space: nowrap;
  }

  .navbar {
    --bg: #000000;
    --fg: #f3f3f3;
    --accent: linear-gradient(90deg, #e8674f 0%, #ffb23e 100%);

    background: var(--bg);
    color: var(--fg);
  }

  .navbarWrapper {
    padding: 0 7.125rem;
    box-sizing: border-box;
  }

  .brandText {
    display: flex;
    align-items: center;
  }

  .brandText img {
    padding: 1em 0;
    width: 2em;
  }

  .brandText.show img {
    padding-top: 1em;
    padding-bottom: 0.3em;
  }

  .brandText > *:not(:last-child) {
    margin-right: 8px;
  }

  .brand {
    display: flex;
    min-height: 3em;
  }

  .brandWrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }

  .brandWrapper > * {
    outline: none;
  }

  .collapse {
    display: none;
    max-height: 0;
    height: 100%;
    transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
  }

  .collapse > * {
    flex-direction: column;
    width: 100%;
    max-height: 1000px;
  }

  .collapse > * > * {
    margin-right: 0 !important;
  }

  .collapse.show {
    max-height: 1000px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    transition: max-height 0.4s ease-in-out;
    padding: 0;
  }

  .toggle {
    cursor: pointer;
  }

  .toggle > * {
    display: block;
  }

  .button-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1em;
    /*margin-left: 2em;*/
    padding-left: 2em;
    width: max-content;
  }

  .navbar-btn:hover {
    opacity: 0.75;
  }

  .button-container .navbar-btn {
    border-radius: 50px;
    color: #ffcb15;
    cursor: pointer;
    outline: none;
    border: 2px solid #ffcb15;
    padding: 0;
    font-weight: normal;
    font-size: inherit;
    margin-top: 0.2em;
  }

  @media (max-width: 1060px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block !important;
    }

    .navbarWrapper {
      padding: 0 1rem;
    }

    .button-container {
      margin: 0;
      width: 100%;
      padding: 0;

      img {
        height: 1.5rem;
      }

      .navbar-btn {
        border: none;
        padding: 0;
        margin: 0;
      }
    }

    .user-container {
      width: 100%;
      display: flex;

      .mobile-dropdown {
        width: 100%;
        padding: 0 1rem;
        cursor: pointer;

        .dropdown-container {
          padding: 0;
          .dropdown.show {
            display: flex;
            flex-direction: column;
            height: max-content;

            .top {
              padding: 1rem 0;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding-bottom: 0;
            }
            .bottom {
              padding: 1rem 0;
              border: none;
              padding-top: 0;
            }
          }
        }

        &:hover {
          opacity: 0.75;
        }

        div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: bold;

          img {
            width: 0.698125rem;
          }

          h2 {
            font-size: 14px;
          }
        }
      }

      #user {
        height: 1.5rem;
      }
    }
  }

  @media only screen and (min-width: 1060px) {
    .collapse {
      margin-left: 50px;
    }

    .button-container .navbar-btn {
      padding: 0.5em 1.3em;
      font-size: 0.9em;
      margin-top: 0;
    }

    .button-container {
      justify-content: center;
      align-items: center;
    }

    .brandText img {
      height: 3.8em;
      width: auto;
    }

    .collapse > * {
      flex-direction: row;
      margin-bottom: 0;
      max-height: none;
      max-height: initial;
    }

    .collapse > * > *:not(:last-child) {
      margin-right: 34px !important;
    }

    .collapse > * > *:not(:last-child) {
      margin-right: 48px;
    }

    .collapse.show {
      flex-direction: row;
      height: 100%;
    }

    .toggle {
      display: none;
    }

    .navbar {
      height: 4.1875rem;
    }

    .navbarWrapper {
      display: flex;
      justify-content: space-between;
      height: 100%;
      box-sizing: border-box;
    }

    .brandText * {
      display: block;
    }
  }
`

export default NavbarCSS
