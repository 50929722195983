import React, { useState } from "react"
import { Formik, Form, Field } from "formik"
import { connect } from "react-redux"
import { forgotPassword } from "../../redux/slices/session/thunks"
import { isLoggedIn } from "../../redux/slices/session/selectors"
import { ForgotPasswordCSS } from "./ForgotPasswordCSS"
import LoginPageWrapper from "../LoginPageComponent/LoginPageWrapper"
import Button from "../Button"

function ForgotPasswordComponent(props) {
  const [isSent, setSent] = useState(false)

  return (
    <ForgotPasswordCSS>
      <LoginPageWrapper
        setShowLogin={() => props.setShowLogin()}
        title="RESET PASSWORD"
      >
        {!isSent && (
          <>
            <h1 id="reset-password-desc">
              Enter your user account’s verified email address and we will send
              you a password reset link.
            </h1>
            <Formik
              initialValues={{
                email: "",
              }}
              onSubmit={async ({ email }) => {
                try {
                  await props.forgotPassword(email)
                  setSent(true)
                } catch {
                  // console.log("err")
                }
              }}
            >
              <Form>
                {!isSent && (
                  <>
                    <label htmlFor="email">Email</label>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Input your E-mail for password recovery instruction"
                    />

                    <Button type="submit" className="submit-btn">
                      Reset Password
                    </Button>
                  </>
                )}
              </Form>
            </Formik>
          </>
        )}

        {isSent && (
          <p className="info">
            Check your email for a link to reset your password. If it doesn’t
            appear within a few minutes, check your spam folder.
          </p>
        )}
        <h1 className="register">
          Remember your password?{" "}
          <a onClick={() => props.activateLoginState(0)}>Sign In</a>
        </h1>
      </LoginPageWrapper>
    </ForgotPasswordCSS>
  )
}

export default connect(
  (state) => ({
    isLoggedIn: isLoggedIn(state),
  }),
  { forgotPassword }
)(ForgotPasswordComponent)
