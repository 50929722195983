import React from "react";
import { LoginWrapperCSS } from './LoginWrapperCSS'
import Logo from '../../../assets/images/login-assets/compfest-white.svg'
import Cross from '../../../assets/images/login-assets/cross.svg'

const LoginPageComponent = (props) => {
  return (
    <LoginWrapperCSS>
      <div className="cross">
        <img onClick={() => props.setShowLogin()} src={Cross}></img>
      </div>
      <div className="main">
        <div className="login-head">
          <img className="logo" src={Logo}></img>
          <h1 className='login-head-title'>{props.title}</h1>
        </div>
        {props.children}
      </div>
    </LoginWrapperCSS>
  );
}

export default LoginPageComponent
