import styled from "styled-components"
export const LoginWrapperCSS = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1em;

  * {
    font-family: "DM Sans";
  }

  *:focus {
    outline: none;
  }

  a {
    cursor: pointer;
  }

  .main {
    width: 27em;
  }

  .login-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3em;
    margin-bottom: 4em;
    
    .logo {
      margin-bottom: .5em;
      width: 8em;
    }

    h1 {
      font-family: "itc";
      font-size: 2.8em;
      margin: 0;
      background: linear-gradient(106.03deg, #49AB64 23.82%, #DC7929 42.6%, #E72836 56.78%, #7723BC 77.64%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
    }
  }

  .forgot-password {
    color: #ffb23e;
    font-size: .9em;
  }
  
  label {
    font-weight: 500;
  }

  input {
    font-size: 1em;
    border: 2px solid #F3F3F3;
    box-sizing: border-box;
    border-radius: 5px;
    background: none;
    color: #F3F3F3;
    padding: 0.5em 0.5em;
    margin: 0.5em 0;
    margin-bottom: 1.5em;
    caret-color: #F3F3F3;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px black inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #F3F3F3 !important;
    -webkit-text-font-family: itc;
  }

  input::placeholder {
    font-size: .9em;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .register {
    font-weight: normal;
    font-size: .85em;
    text-align: center;

    a, span {
      color: #ffb23e;
    }

    span {
      cursor: pointer;
    }
  }

  .submit-btn {
    font-family: "itc";
    font-size: 1em;
    padding: 0.6em 1em;
    font-weight: 500;
  }

  .cross {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    cursor: pointer;

    img {
      padding-top: 2.5em;
      padding-right: 7em;
      width: 1.5em;
    }
  }

  .cross:hover {
    opacity: 0.75;
  }

  .info {
    font-weight: 500;
    font-size: 1.05em;
    text-align: center;
    line-height: 140%;
  }

  .submit-btn {
    margin: 4em 0 1em 0;
  }

  @media (max-width: 1920px) {
    font-size: 1.2em;
  }

  @media (max-width: 1366px) {
    font-size: 1em;
  }

  @media (max-width: 768px) {
    .main {
      width: 75%;
    }

    .login-head {
      margin-bottom: 6em;
    }

    .cross {
      img {
        padding: 2em;
        padding-bottom: 0;
        width: 1.5em;
      }
    }

    .submit-btn {
      margin-top: 3em;
    }
  }
`