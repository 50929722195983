import React from "react"
import styled from "styled-components"
import compfestMobile from "../../../assets/images/loading.gif"

const BarStyle = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // animation: blink 1.5s infinite both;

    // @keyframes blink {
    //   0% {
    //     opacity: 0.2;
    //   }
    //   10% {
    //     opacity: 0.3;
    //   }
    //   25% {
    //     opacity: 0.5;
    //   }
    //   35% {
    //     opacity: 0.7;
    //   }
    //   50% {
    //     opacity: 1;
    //   }
    //   65% {
    //     opacity: 0.7;
    //   }
    //   75% {
    //     opacity: 0.5;
    //   }
    //   90% {
    //     opacity: 0.3;
    //   }
    //   100% {
    //     opacity: 0.2;
    //   }
    // }

    > img {
      height: 350px;
      width: 650px;
    }
  }
`

const Loading = () => {
  return (
    <BarStyle>
      <div>
        <img src={compfestMobile} alt="COMPFEST" />
      </div>
    </BarStyle>
  )
}

export default Loading
