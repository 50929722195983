export const theme = {
  border: {
    buttonRadius: "10px",
    cardRadius: "10px",
  },
  colors: {
    primary: "#f3f3f3",
    background: "#000000",
    black: "#000000",
    blue: "#383089",
    darkBlue: "#14062F",
    darkPink: "#CB0058",
    darkPurple: "#490074",
    lightBlue: "#302858",
    pink: "#EC006A",
    purple: "#992F8B",
    red: "#EF2915",
    white: "#FFFFFF",
    yellow: "#FFCB15",
    whiteSmoke: "#F8F8F8",
  },
  font: {
    silka: "'silka', sans-serif",
    itc: "'itc', sans-serif",
  },
  shadow: {
    normal: "0px -1px 1px rgba(0, 0, 0, 0.1)",
    bold: "0px 4px 4px rgba(0, 0, 0, 0.25);",
  },
}
