export class BadRequestError extends Error {
  name = "BadRequestError"
}

export class UnauthorizedError extends Error {
  name = "UnauthorizedError"
}

export class ForbiddenError extends Error {
  name = "ForbiddenError"
}

export class NotFoundError extends Error {
  name = "NotFoundError"
}

export class RemoteError extends Error {
  name = "RemoteError"
}
