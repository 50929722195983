/* eslint-disable no-unused-expressions */
import React, { useState } from "react"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import { Formik, Field, Form } from "formik"
import Button from "../Button"
import { login } from "../../redux/slices/session/thunks"
import { isLoggedIn } from "../../redux/slices/session/selectors"
import LoginPageWrapper from "./LoginPageWrapper"
import ForgotPasswordComponent from "../ForgotPasswordComponent"
import ResetPasswordComponent from "../ResetPasswordComponent"
import LoginCSS from "./LoginCSS"

const LoginPageComponent = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [loginState, setLoginState] = useState([true, false, false])
  const [showLogin, setShowLogin] = useState(loginState[0])
  const [showForgetPassword, setShowForgetPassword] = useState(loginState[1])
  const [showResetPassword, setShowResetPassword] = useState(loginState[2])

  const activateLoginState = (idx) => {
    let temp = loginState
    temp = temp.fill(false)
    temp[idx] = true
    setLoginState(temp)
    setShowLogin(loginState[0])
    setShowForgetPassword(loginState[1])
    setShowResetPassword(loginState[2])
  }

  return showLogin ? (
    <LoginCSS>
      <LoginPageWrapper
        setShowLogin={() => props.setShowLogin()}
        title="SIGN IN"
      >
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={async ({ email, password }, { setSubmitting }) => {
            try {
              setIsLoading(true)
              await props.login(email, password)
            } catch (e) {
              setIsLoading(false)
              setSubmitting(false)
              return
            }
            setIsLoading(false)
            setSubmitting(false)
            setTimeout(() => {
              props.setShowLogin(false)
              navigate("/")
            }, 2000)
          }}
        >
          {(formik) => (
            <Form>
              <label htmlFor="email">Email</label>
              <Field
                name="email"
                type="email"
                placeholder="johnney@cs.ui.ac.id"
              />

              <label htmlFor="password">Password</label>
              <Field
                name="password"
                type="password"
                placeholder="Enter password"
              />

              <a
                className="forgot-password"
                onClick={() => activateLoginState(1)}
              >
                Forgot password?
              </a>

              <Button
                type="submit"
                disabled={formik.isSubmitting}
                className="submit-btn"
              >
                {isLoading ? "Authenticating" : "Sign In"}
              </Button>
            </Form>
          )}
        </Formik>
        <h1 className="register">
          Don't have an account?{" "}
          <span onClick={props.setShowRegister}>Sign Up</span>
        </h1>
      </LoginPageWrapper>
    </LoginCSS>
  ) : showForgetPassword ? (
    <ForgotPasswordComponent
      activateLoginState={(idx) => activateLoginState(idx)}
      setShowLogin={() => props.setShowLogin()}
    />
  ) : showResetPassword ? (
    <ResetPasswordComponent setShowLogin={() => props.setShowLogin()} />
  ) : (
    <></>
  )
}

export default connect(
  (state) => ({
    isLoggedIn: isLoggedIn(state),
  }),
  { login }
)(LoginPageComponent)
