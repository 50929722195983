import React from "react"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import { Formik, Form, Field } from "formik"
import { Input } from "jasper-ui"

import LoginPageWrapper from "../LoginPageComponent/LoginPageWrapper"
import Button from "../Button"
import Loading from "../Loading"

import { isDateSupported } from "../ManageProfile"
import { isLoggedIn, getAuthUser } from "../../redux/slices/session/selectors"
import { signup, updateProfile } from "../../redux/slices/session/thunks"
import { toastActions } from "../../redux/toast"
import calendar from "../../../assets/images/register-assets/calendar.svg"

import { Styles } from "./style"

class RegisterPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      registerStep: 0,
      isLoading: false,
      newUser: null,
    }
  }

  render() {
    const props = this.props

    const { registerStep, isLoading, newUser } = this.state

    if (isLoading) {
      if (!props.loading && props.user && props.isLoggedIn && newUser) {
        const theUser = Object.assign({}, props.user)
        for (const [key, value] of Object.entries(newUser)) {
          if (key in theUser) {
            theUser[key] = value
          }
        }
        this.setState({ newUser: null })
        props
          .updateProfile(theUser, true)
          .then(() => {
            setTimeout(
              () =>
                typeof window !== "undefined" &&
                props.closeBox(false) &&
                navigate("/dashboard/profile"),
              1000
            )
          })
          .catch(() => {
            toastActions.showErrorToast("Failed to set profile")
            setTimeout(
              () =>
                typeof window !== "undefined" && navigate("/dashboard/profile"),
              1000
            )
          })
      }
      return <Loading />
    }

    const backButton = (
      <Button
        onClick={() => {
          this.setState({ registerStep: Math.max(registerStep - 1, 0) })
        }}
        className="secondary"
      >
        Back
      </Button>
    )

    const checkParts = [
      ["firstName", "lastName", "birthdate", "gender"],
      ["email", "password", "rePassword"],
      ["phone_number", "institution"],
    ]

    const mapKeysToName = {
      firstName: "First Name",
      lastName: "Last Name",
      birthdate: "Date of Birth",
      gender: "Gender",
      email: "Email",
      password: "Password",
      rePassword: "Confirm Password",
      phone_number: "Phone Number",
      institution: "Institution",
    }

    return (
      <Styles>
        <LoginPageWrapper setShowLogin={props.closeBox} title="SIGN UP">
          <div className="signup-progress-bar">
            <img
              src={`/content/img/sign_up_progress${registerStep}.svg`}
              alt={`sign up progress ${this.state.registerStep}`}
            />
          </div>

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              birthdate: "",
              gender: "",
              email: "",
              password: "",
              rePassword: "",
              phone_number: "",
              institution: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              let errors = ""
              checkParts[registerStep].forEach((key) => {
                if (!values[key]) {
                  errors += `, ${mapKeysToName[key]} must not empty`
                }
              })
              if (registerStep === 1) {
                if (values.password !== values.rePassword) {
                  errors += ", Password didn't match"
                }
                if (values.password.length < 8) {
                  errors += ", Password minimum 8 characters"
                }
              }

              if (errors !== "") {
                toastActions.showErrorToast(errors.slice(2))
                setSubmitting(false)
              } else if (registerStep === 2) {
                this.setState({ isLoading: true, newUser: values })
                const name = values.firstName + " " + values.lastName
                props
                  .signup(name, values.email, values.password)
                  .then(() => {
                    setSubmitting(false)
                  })
                  .catch((err) => {
                    errors = ""
                    if (err.response && err.response.data) {
                      Object.values(err.response.data).forEach((x) => {
                        errors += `, ${x.join(" ")}`
                      })
                    }
                    if (errors) {
                      toastActions.showErrorToast(errors.slice(2))
                    } else {
                      toastActions.showErrorToast("Sign up failed!")
                    }
                  })
                  .finally(() => {
                    this.setState({ isLoading: false, registerStep: 0 })
                    setSubmitting(false)
                  })
              } else if (registerStep < 2) {
                this.setState({ registerStep: registerStep + 1 })
              }
              setSubmitting(false)
            }}
          >
            {(formik) => (
              <Form>
                {registerStep === 0 ? (
                  <>
                    <label htmlFor="firstName">First Name</label>
                    <Field name="firstName" type="text" placeholder="John" />

                    <label htmlFor="lastName">Last Name</label>
                    <Field
                      name="lastName"
                      type="text"
                      placeholder="Appleseed"
                    />

                    <label htmlFor="birthdate">Date of Birth</label>
                    <div id="calendar">
                      <img src={calendar} alt="calendar" id="calendar-logo" />
                      <Field name="birthdate" type="date" className="w-100" />
                    </div>

                    {isDateSupported ? (
                      <></>
                    ) : (
                      <div>
                        Enter the date with the following format: YYYY-MM-DD
                      </div>
                    )}

                    <label htmlFor="gender">Gender</label>
                    <div className="radio-flex" id="gender">
                      <div className="radio-each">
                        <Field
                          name="gender"
                          value="F"
                          type="radio"
                          id="genderFemale"
                        />
                        <label htmlFor="genderFemale"> Female</label>
                      </div>
                      <div className="radio-each">
                        <Field
                          name="gender"
                          value="M"
                          type="radio"
                          id="genderMale"
                        />
                        <label htmlFor="genderMale"> Male</label>
                      </div>
                    </div>
                  </>
                ) : registerStep === 1 ? (
                  <>
                    <label htmlFor="email">E-mail</label>
                    <Field
                      name="email"
                      type="email"
                      placeholder="johnney@cs.ui.ac.id"
                    />

                    <label htmlFor="password">Password</label>
                    <Field
                      name="password"
                      type="password"
                      placeholder="Create password. minimum 8 characters"
                    />

                    <label htmlFor="rePassword">Confirm Password</label>
                    <Field
                      name="rePassword"
                      type="password"
                      placeholder="re-type your password"
                    />
                  </>
                ) : registerStep === 2 ? (
                  <>
                    <label htmlFor="phone_number">Phone Number</label>
                    <div className="input-phone">
                      <Input.Phone
                        name="phone_number"
                        placeholder="8123456789"
                      />
                    </div>

                    <label htmlFor="institution">Institution</label>
                    <Field
                      name="institution"
                      type="text"
                      placeholder="Enter your workplace/study institution"
                    />
                  </>
                ) : (
                  <></>
                )}

                <div
                  className="buttons-flex"
                  style={
                    this.state.registerStep > 0
                      ? {}
                      : { justifyContent: "flex-end" }
                  }
                >
                  {registerStep > 0 ? backButton : <></>}
                  {registerStep < 2 ? (
                    <Button type="submit" disabled={formik.isSubmitting}>
                      Next
                    </Button>
                  ) : (
                    <Button type="submit" disabled={formik.isSubmitting}>
                      Submit
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
          <div className="signup-bottom-gap" />
        </LoginPageWrapper>
      </Styles>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
  loading: state.session.accountIsLoading,
  user: getAuthUser(state),
})

const mapDispatchToProps = {
  signup,
  updateProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
