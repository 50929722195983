import styled from "styled-components"

export const Styles = styled.div`
  .logo {
    display: none;
  }

  .login-head {
    margin-bottom: 30px;
  }

  #calendar {
    position: relative
  }

  #calendar-logo {
    position: absolute;
    right:10px;
    top: 25%;
    z-index: -1;
  }

  .w-100 {
    width: 100%;
  }

  .signup-progress-bar {
    padding-bottom: 60px;
  }

  .signup-progress-bar img {
    width: 100%;
  }

  .buttons-flex {
    display: flex;
    justify-content: space-between;
  }

  .radio-flex {
    display: flex;
    justify-content: space-between;
    width: 90%;
  }

  .radio-each {
    display: inline-block;
  }

  .signup-bottom-gap {
    padding-bottom: 50px;
  }
  
  .input-phone > div > span {
    margin: 0;
    margin-top: 1.1em;
    margin-left: 15px;
    z-index: 21;
  }

  .input-phone > div > span::after {
    top: -2px;
  }

  .input-phone input {
    width: 100% !important;
  }
`