import styled from "styled-components"
const LoginCSS = styled.div`
  input[type="password"] {
    margin-bottom: 1em;
  }

  .login-head h1 {
    width: 8em;
    font-size: 2.8em;
    margin: 0;
    background: linear-gradient(
      89.68deg,
      #49ab64 -3.47%,
      #dc7929 35.23%,
      #e72836 64.45%,
      #7723bc 107.41%
    );
    background-size: contain;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  button {
    width: 100%;
  }
`
export default LoginCSS
