/* eslint-disable react/no-did-update-set-state */
import React, { Component } from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { navigate } from "gatsby"
import { Input } from "jasper-ui"
import { Formik, Form, Field, ErrorMessage } from "formik"

import sessionThunks from "../../redux/slices/session/thunks"
import { getAuthUser } from "../../redux/slices/session/selectors"
import withLogin from "../withLoggedIn"
import zeusAccount from "../../api/zeus/account"
import { toastActions } from "../../redux/toast"

import { OprecProfileSchema } from "../../schemas"
import Button from "../Button"

import { Profile } from "./style"
import Image from "../image"
import Loading from "../Loading"

const mapStateToProps = (state) => ({
  user: getAuthUser(state),
  location: "/manageprofile",
  loading: state.session.accountIsLoading,
})

const mapDispatchToProps = {
  updateProfile: sessionThunks.updateProfile,
  push,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

class ManageProfile extends Component {
  state = {
    isDisabled: true,
    isVerified: null,
    changePassword: false,
  }

  editForm = () => {
    this.setState((prevState) => ({
      isDisabled: !prevState.isDisabled,
      changePassword: false,
    }))
  }

  update = (profile) => {
    const { user } = this.props

    if (profile.password && profile.password === profile.rePassword) {
      zeusAccount
        .putPassword(profile.oldPassword, profile.password)
        .then((value) => {
          const message = value.data.message
          toastActions.showToast(
            message.charAt(0).toUpperCase() + message.slice(1)
          )
        })
        .catch(() => {
          toastActions.showErrorToast("Failed to change password")
        })
    }

    profile.name = profile.firstName + " " + profile.lastName
    this.props.updateProfile(profile)
    this.editForm()
    if (!user.is_verified) {
      navigate(history.state.url)
    }
  }

  isDateSupported = () => {
    var input = document.createElement("input")
    var value = "a"
    input.setAttribute("type", "date")
    input.setAttribute("value", value)
    return input.value !== value
  }

  componentDidUpdate = (prevProps) => {
    const { user } = this.props
    if (user && prevProps.user.is_verified !== user.is_verified) {
      this.setState({
        isVerified: user.is_verified,
      })
    }
  }

  getAge = (birthdate) => {
    birthdate = new Date(birthdate)
    const timeDiff = new Date(Date.now() - birthdate.getTime())
    return Math.abs(timeDiff.getUTCFullYear() - 1970)
  }

  render() {
    const props = this.props
    const { isDisabled, changePassword } = this.state

    const { loading } = this.props

    if (!props.user) {
      typeof window !== "undefined" && navigate("/")
      return <></>
    }

    if (loading) {
      return <Loading />
    }

    let userValues = Object.assign({}, props.user)
    userValues = {
      ...userValues,
      firstName: userValues.name.split(" ")[0],
      lastName: userValues.name.split(" ").slice(1).join(" "),
      age: userValues.birthdate ? this.getAge(userValues.birthdate) : "",
      password: "",
      rePassword: "",
      oldPassword: "",
      birthdate: userValues.birthdate || "",
      gender: userValues.gender || "",
      phone_number: userValues.phone_number || "",
      institution: userValues.institution || "",
    }

    const queryDict = {}
    // console.log(props)
    typeof location !== "undefined" &&
      location.search // eslint-disable-line no-undef
        .substr(1)
        .split("&")
        .forEach(function (item) {
          queryDict[item.split("=")[0]] = item.split("=")[1]
        })
    // console.log(queryDict)

    return (
      <Profile>
        <div className="title-container desktop">
          {isDisabled ? (
            <>
              {/* <Link to={queryDict.back ? queryDict.back : "/applications"}>
                <div className="back-button">
                  <div id="back">
                    <Image imgName="arrow-back.png" alt="back" />
                  </div>
                </div>
              </Link> */}
              <h1 className="title">PROFILE PAGE</h1>
            </>
          ) : (
            <h1 className="edit-title">EDIT PROFILE</h1>
          )}
        </div>
        <div className="profile-container">
          <div className={`asset-container ${isDisabled ? "" : "stay-top"}`}>
            <div id="asset">
              <Image imgName="edit_profile_asset.png" alt="Logo" />
            </div>
          </div>
          <div className="title-container mobile">
            {isDisabled ? (
              <>
                {/* <Link to={queryDict.back ? queryDict.back : "/applications"}>
                  <div className="back-button">
                    <div id="back">
                      <Image imgName="arrow-back.png" alt="back" />
                    </div>
                  </div>
                </Link> */}
                <h1 className="title">PROFILE PAGE</h1>
              </>
            ) : (
              <h1 className="edit-title">EDIT PROFILE</h1>
            )}
          </div>
          <div className={`form-container ${isDisabled ? "" : "remove-space"}`}>
            {!props.user && <div>Loading...</div>}
            {props.user && (
              <>
                <Formik
                  initialValues={userValues}
                  validationSchema={OprecProfileSchema}
                  onSubmit={(profile) => this.update(profile)}
                >
                  <Form className="form-container">
                    <div
                      className={
                        props.user.is_verified ? "form top-gap" : "form"
                      }
                    >
                      {/* !props.user.is_verified && (
                        <div className="please-verify">
                          <h3 css={{ color: "#EF2915" }}>
                            Please verify your email and complete your profile
                            first before continue
                          </h3>
                        </div>
                      ) */}
                      <table>
                        <tbody>
                          <tr>
                            {isDisabled ? (
                              <th>
                                <label htmlFor="name">Name</label>
                                <Field name="name" disabled />
                              </th>
                            ) : (
                              <>
                                <th id="fullname">
                                  <td>
                                    <label htmlFor="name">First Name</label>
                                    <Field name="firstName" />
                                  </td>
                                  <td>
                                    <label htmlFor="name">Last Name</label>
                                    <Field name="lastName" />
                                  </td>
                                </th>
                              </>
                            )}
                          </tr>
                          {isDisabled ? (
                            <tr>
                              <th>
                                <label htmlFor="name">Age</label>
                                <Field name="age" disabled />
                              </th>
                            </tr>
                          ) : (
                            <tr>
                              <th>
                                <label htmlFor="birthdate">Birth Date</label>
                                <Field
                                  name="birthdate"
                                  type="date"
                                  disabled={isDisabled}
                                />
                                {!this.isDateSupported() && (
                                  <div>
                                    Enter the date with the following format:
                                    YYYY-MM-DD
                                  </div>
                                )}
                                <ErrorMessage name="birthdate" />
                              </th>
                            </tr>
                          )}
                          {isDisabled ? (
                            <tr>
                              <th>
                                <label htmlFor="gender">Gender</label>
                                {userValues.gender ? (
                                  <>
                                    <Field
                                      component="select"
                                      name="gender"
                                      disabled
                                    >
                                      <option value="" disabled hidden>
                                        Select your gender
                                      </option>
                                      <option value="M">Male</option>
                                      <option value="F">Female</option>
                                      <option value="U">Unspecified</option>
                                    </Field>
                                    <ErrorMessage name="gender" />
                                  </>
                                ) : (
                                  <div style={{ height: "50px" }} />
                                )}
                              </th>
                            </tr>
                          ) : (
                            <tr>
                              <th>
                                <label htmlFor="gender">Gender</label>
                                <div className="radio-flex" id="gender">
                                  <div className="radio-each">
                                    <Field
                                      name="gender"
                                      value="F"
                                      type="radio"
                                      id="genderFemale"
                                    />
                                    <label htmlFor="genderFemale">
                                      {" "}
                                      Female
                                    </label>
                                  </div>
                                  <div className="radio-each">
                                    <Field
                                      name="gender"
                                      value="M"
                                      type="radio"
                                      id="genderMale"
                                    />
                                    <label htmlFor="genderMale"> Male</label>
                                  </div>
                                  <div className="radio-each">
                                    <Field
                                      name="gender"
                                      value="U"
                                      type="radio"
                                      id="genderUnspecified"
                                    />
                                    <label htmlFor="genderUnspecified">
                                      {" "}
                                      Unspecified
                                    </label>
                                  </div>
                                </div>
                                <ErrorMessage name="gender" />
                              </th>
                            </tr>
                          )}
                          <tr>
                            <th>
                              <label htmlFor="email">Email</label>
                              <Field name="email" disabled={isDisabled} />
                            </th>
                          </tr>
                          <tr>
                            <th>
                              <label htmlFor="phone_number">Phone Number</label>
                              {isDisabled ? (
                                <div className="disabled-phone">
                                  {userValues.phone_number ? (
                                    <span className="plus62">+62</span>
                                  ) : (
                                    <></>
                                  )}
                                  <Field
                                    name="phone_number"
                                    disabled={isDisabled}
                                  />
                                </div>
                              ) : (
                                <>
                                  <div className="input-phone">
                                    <Input.Phone
                                      name="phone_number"
                                      placeholder="8123456789"
                                      disabled={isDisabled}
                                    />
                                  </div>
                                  <ErrorMessage name="phone_number" />
                                </>
                              )}
                            </th>
                          </tr>
                          <tr>
                            <th>
                              <label htmlFor="name">Institution</label>
                              <Field name="institution" disabled={isDisabled} />
                              {isDisabled || (
                                <ErrorMessage name="institution" />
                              )}
                            </th>
                          </tr>
                          {isDisabled ? (
                            <></>
                          ) : changePassword ? (
                            <>
                              <tr>
                                <th>
                                  <label htmlFor="oldPassword">
                                    Old Password
                                  </label>
                                  <Field name="oldPassword" type="password" />
                                  <ErrorMessage name="oldPassword" />
                                </th>
                              </tr>
                              <tr>
                                <th>
                                  <label htmlFor="password">New Password</label>
                                  <Field name="password" type="password" />
                                  <ErrorMessage name="password" />
                                </th>
                              </tr>
                              <tr>
                                <th>
                                  <label htmlFor="rePassword">
                                    Confirm Password
                                  </label>
                                  <Field name="rePassword" type="password" />
                                  <ErrorMessage name="rePassword" />
                                </th>
                              </tr>
                            </>
                          ) : (
                            <tr>
                              <th>
                                <label>Current Password</label>
                                <Field readonly value="**********" />
                                <div id="edit-password">
                                  <Button
                                    className="secondary"
                                    css={{
                                      marginTop: "30px",
                                      borderColor: "#FFB23E!important",
                                      color: "#FFB23E!important",
                                    }}
                                    onClick={() =>
                                      this.setState({ changePassword: true })
                                    }
                                  >
                                    Edit Password
                                  </Button>
                                </div>
                              </th>
                            </tr>
                          )}
                          {/* <tr>
                            <th>
                              <label htmlFor="line_id">Line ID</label>
                              <Field name="line_id" disabled={isDisabled} />
                              <ErrorMessage name="line_id" />
                            </th>
                          </tr> */}
                          <tr>
                            <th className="edit-buttons">
                              {/* <h1>{props.user.name}</h1> */}
                              {isDisabled ? (
                                <Button
                                  css={{ marginTop: "30px" }}
                                  className="secondary"
                                  onClick={this.editForm}
                                >
                                  Edit Profile
                                </Button>
                              ) : (
                                <div>
                                  <Button
                                    css={{
                                      marginTop: "30px",
                                      marginRight: "10px",
                                    }}
                                    type="submit"
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    css={{
                                      marginTop: "10px",
                                    }}
                                    className="secondary"
                                    onClick={this.editForm}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              )}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Form>
                </Formik>
              </>
            )}
          </div>
        </div>
      </Profile>
    )
  }
}

export const isDateSupported = () => {
  var input = document.createElement("input")
  var value = "a"
  input.setAttribute("type", "date")
  input.setAttribute("value", value)
  return input.value !== value
}

export default compose(withLogin, withConnect)(ManageProfile)
