import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { ThemeProvider } from "styled-components"
import { ToastContainer } from "react-toastify"

// components
import { theme } from "./theme"
import Navbar from "./Navbar"
import Nav from "./Navbar/Nav"
import Footer from "./footer"
import LoginPageComponent from "./LoginPageComponent"
import ResetPasswordComponent from "./ResetPasswordComponent"
import RegisterPageComponent from "./RegisterPageComponent"
import StickyAnnouncement from "./CompfestTalksPage/StickyAnnouncement"

// images
import LogoDesktop from "../../assets/images/navbar-assets/logo_desktop.svg"
import LogoMobile from "../../assets/images/navbar-assets/logo_mobile.svg"

import "react-toastify/dist/ReactToastify.css"
import "./layout.css"

const DefaultWrapper = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
    <ToastContainer
      toastClassName="toast-container"
      bodyClassName="toast-body"
      position="bottom-center"
      hideProgressBar
      autoClose={4000}
    />
  </ThemeProvider>
)

const Layout = ({ children, noAnnouncement }) => {
  const [showLogin, setShowLogin] = useState(false)
  const [showRegister, setShowRegister] = useState(false)
  const [showReset, setShowReset] = useState(false)

  useEffect(() => {
    const path = typeof window !== "undefined" && window.location.search
    if (typeof path === "string" && path.indexOf("?token=") > -1) {
      setShowReset(true)
    }
  }, [])

  return showReset ? (
    <ResetPasswordComponent
      closeResetPassword={() => {
        setShowReset(false)
        navigate("/")
      }}
      openLogin={() => {
        navigate("/")
        setShowLogin(true)
      }}
    />
  ) : showRegister ? (
    <DefaultWrapper>
      <RegisterPageComponent
        closeBox={() => {
          setShowLogin(false)
          setShowRegister(false)
        }}
      />
    </DefaultWrapper>
  ) : showLogin ? (
    <DefaultWrapper>
      <LoginPageComponent
        setShowLogin={() => setShowLogin(false)}
        setShowRegister={() => setShowRegister(true)}
      />
    </DefaultWrapper>
  ) : (
    <DefaultWrapper>
      <Navbar setShowLogin={() => setShowLogin(true)}>
        <Navbar.Brand>
          <div mobile="true">
            <img src={LogoMobile} />
          </div>
          <div>
            <img src={LogoDesktop} />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/compfesttalks">COMPFEST Talks</Nav.Link>
            <Nav.Link href="/job-fair" localized>
              Job Fair
            </Nav.Link>
            <Nav.Link href="/playground" localized>
              Playground
            </Nav.Link>
            <Nav.Link href="/academy">Academy</Nav.Link>
            <Nav.Link href="/competition">Competition</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
        <div id="layout">
          <main>{children}</main>
        </div>
        <footer>
          <Footer />
        </footer>
      </div>
      {noAnnouncement ? <></> : <StickyAnnouncement />}
    </DefaultWrapper>
  )
}

Layout.defaultProps = {
  noAnnouncement: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noAnnouncement: PropTypes.bool,
}

export default Layout
