import styled from "styled-components"

export const Profile = styled.div`
  .title,
  .edit-title {
    text-align: center;
    background: linear-gradient(
      89.81deg,
      #49ab64 -3.47%,
      #dc7929 35.23%,
      #e72836 64.45%,
      #7723bc 107.41%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -0.7em;
    margin-right: 1em;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: linear-gradient(180deg, #d418b6 0%, #f1185e 100%);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }

  .back-button #back {
    width: 15px;
    height: 17.31px;
  }

  .profile-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -3.5em;

    @media (max-width: 768px) {
      margin-top: 0;
      flex-direction: column;
      height: 100%;
    }
  }

  .disabled-phone {
    display: flex;
    align-items: center;
  }

  .plus62 {
    font-size: 1.5em;
    font-weight: 900;
    color: white;
    margin-top: 7px;
    margin-bottom: 7px;
    padding: 1px 2px;
  }

  .input-phone > div > span {
    margin: 0;
    margin-top: 19px;
    margin-left: 15px;
    z-index: 21;
  }

  .input-phone > div > span::after {
    top: -2px;
  }

  .form input {
    margin-top: 7px;
  }

  .form input[type="radio"] {
    width: initial;
    height: initial;
  }

  .radio-flex {
    display: flex;
    justify-content: space-between;
    width: 90%;
    flex-wrap: wrap;
  }

  .radio-each {
    display: inline-block;
  }

  .asset-container {
    object-fit: cover;
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: center;
  }

  .stay-top {
    margin-bottom: 6%;
  }

  .form-container {
    min-width: 40%;
    display: flex;
    flex-direction: column;
  }

  .remove-space {
    margin-top: -0.75%;
  }

  .please-verify h3 {
    max-width: 600px;
    padding-bottom: 10px;
  }

  table {
    max-width: 400px;
    width: 90%;
  }

  th,
  td {
    padding-bottom: 10px;
  }

  th {
    width: 100px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.red};
    padding-right: 20px;
    text-align: left;
  }

  td {
    width: 250px;
    padding-right: 20px;
    color: ${(props) => props.theme.colors.red};
    margin-top: 5px;
  }

  #fullname {
    padding-left: 0;
  }

  #fullname td {
    width: 50%;
  }

  #fullname td:first-child {
    padding-left: 0;
    padding-right: 2.5em;
  }

  #fullname td:last-child {
    padding-left: 2.5em;
    padding-right: 0;
  }

  .asset-container #asset {
    width: 100%;
    z-index: -1;
  }

  .top-gap {
  }

  .form {
    padding: 30px;

    input,
    select {
      padding-left: 1em;
      width: 100%;
      height: 2.5em;
      font-size: 1em;
      background: none;
      border-width: 2px;
      line-height: 1.5;
      margin-bottom: 5px;
      border-color: #f3f3f3;
      outline: none;
      border-radius: 5px;
      color: white;
      font-family: "itc";
      border-top-color: #f3f3f3;
    }

    select:disabled {
      appearance: none;
      opacity: 1;
      //   margin-left: -4px;
    }

    input[name="birthdate"]:disabled {
      //   margin-left: -4px;
    }

    option {
      color: black;
    }

    input:disabled,
    select:disabled {
      border-radius: 0;
      height: 1.5em;
      padding-left: 0;
      font-size: 1.5em;
      font-weight: 900;
      border: 0;
    }

    button {
      margin-top: 24px;
      align-self: center;
      padding: 6px 36px;
    }

    label {
      margin-top: 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: #f3f3f3;
    }
  }

  .form button {
    padding: 10px 20px;
  }

  .input-phone input {
    width: 82% !important;
    padding-top: 0;
    padding-bottom: 0;
  }

  .mobile {
    display: none;
  }

  #edit-password {
    width: 100%;
    margin-left: 20px;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 1280px) {
    .radio-each label {
      font-size: 0.9em;
    }
  }

  @media (max-width: 1024px) and (max-height: 1366px) and (min-width: 1024px) and (min-height: 1366px) {
    .asset-container {
      object-fit: cover;
      margin-left: 0;
    }

    .asset-container #asset {
      padding-left: 17%;
    }

    .stay-top {
      margin-bottom: 13.5%;
    }
  }

  @media (max-width: 768px) {
    .desktop {
      display: none;
    }

    table {
      width: 100%;
    }

    .mobile {
      display: flex;
      margin-top: -1.5em;
    }

    .asset-container {
      object-fit: cover;
    }

    .asset-container #asset {
      padding-left: 17%;
      width: 500px;
    }

    .input-phone > div > span {
      margin-top: 18px;
    }

    .form,
    .form-container > div {
      padding: 0 2em;
    }

    .form-container {
      margin-top: 0;
      margin-bottom: 30px;
      width: 100%;
    }

    #fullname {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    #fullname td {
      width: 90%;
    }

    #fullname td:first-child,
    #fullname td:last-child {
      padding-left: 0;
    }

    .edit-buttons {
      display: flex;
      padding-right: 0;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .radio-each label {
      font-size: 0.8em;
    }

    .plus62 {
      margin-bottom: 5px;
    }

    .input-phone input {
      width: 75% !important;
    }
  }

  @media (min-height: 812px) and (max-height: 812px) {
    .asset-container {
    }
  }

  @media (max-height: 667px) {
    .asset-container {
    }
  }

  @media (max-height: 568px) {
    .asset-container {
    }
  }

  @media (max-width: 768px) and (max-height: 1024px) and (min-width: 768px) and (min-height: 1024px) {
    table {
      min-width: 100%;
    }

    .asset-container {
      object-fit: cover;
    }

    .asset-container #asset {
      padding-left: 40%;
    }

    .form-container {
    }

    #fullname td {
      width: 95%;
    }

    .radio-each label {
      font-size: 1em;
    }

    .input-phone input {
      width: 85% !important;
    }
  }
`
